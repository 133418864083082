import { Box } from "@chakra-ui/react";

interface DropdownProps {
  names: string[] | undefined;
  onSelect: (val: string) => void;
}

export const Dropdown = (props: DropdownProps) => {

  return (
    <>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          height: 'fit-content',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Box style={{
          width: '100%'
        }}>
          {props.names?.map((item) => {
            return <>
              <div style={{
                display: 'flex',
                height: '2.5em',
                width: '100%',
                border: '1px solid grey',
                alignItems: 'center',
                backgroundColor: 'white',
                cursor: 'pointer'

              }}
                onClick={() => { props.onSelect(item) }}
              >
                _{item}
              </div>
            </>
          })}
        </Box>
      </Box>
    </>
  )
}

export default Dropdown;
