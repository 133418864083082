export const getAuth0Domain = () =>
  process.env.REACT_APP_AUTH0_DOMAIN || "dev-jnb74jpjfie0o4g0.us.auth0.com";

export const getAuth0Client = () =>
  process.env.REACT_APP_AUTH0_CLIENT_ID || "7tfNekQhZdb2ue6KE5FUK0mFGvnZWx5R";

export const getAuth0Audience = () =>
  process.env.REACT_APP_AUTH0_AUDIENCE || "https://api.voicipe.com/api/partner";

export const getPartnerBaseUrl = () =>
  process.env.REACT_APP_PARTNER_BASE_URL || "https://api.voiceipe.com/api/mobile/v1/search?q=recipe";
