import { Box, BoxProps, Button, CloseButton, Flex, Image, Input, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Dropdown from "../../page/Dropdown";
import titleImage from '../../resources/voicipe_title.png';
import { BingSearchClient, Recipe, RecipeSchema, RecipeSearchResponse } from "../../service/RecipeService";

interface SidebarContentProps extends BoxProps {
  onClose: () => void;
  setCurRecipe: (q: string, rec: RecipeSchema, num: string) => void;
  setUrl: (url: string) => void;
  q: string | null;
  val: string | null;
}

export const SidebarContent = ({ onClose, setCurRecipe, q, val, setUrl }: SidebarContentProps) => {
  const [recipe, setRecipe] = useState<RecipeSearchResponse>();
  const [, setCurrentRecipe] = useState<Recipe>();
  const [input, setInput] = useState('');

  const service = new BingSearchClient();

  const onSearch = (input: string | undefined) => {
    if (input === undefined) {
      return;
    }
    service.search({ count: 10, q: input }).then((ret: RecipeSearchResponse | undefined) => {
      setRecipe(ret);
      console.log(ret);
    })
  }

  useEffect(() => {
    if (q !== null && q !== '') {
      setInput(q);
      onSearch(q);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q])

  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full">
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between" padding={'18px'}>
        <Image height={'inherit'} maxWidth={'inherit'} src={titleImage} />
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Input
        variant='outline'
        placeholder={'Search recipes...'}
        style={{ margin: '0.5em', width: '93%' }}
        onChange={e => setInput(e.target.value)} />
      <Button
        onClick={() => onSearch(input)}
        style={{ margin: '0.5em', width: '93%' }}>Search</Button>
      <Dropdown names={recipe?.data.map((item) => {
        return item.recipe.name;
      })}
        onSelect={(ret) => {
          console.log(ret);
          const curRec = recipe?.data.find((item) => { console.log(item.recipe); return item.recipe.name === ret });
          console.log(curRec);
          setCurrentRecipe(curRec?.recipe);
          setCurRecipe(input, curRec || {} as RecipeSchema, '');
          setUrl(curRec?.url || '')
        }} />
    </Box>
  );
};
