import { useAuth0 } from '@auth0/auth0-react';
import { ErrorPage } from '../page/ErrorPage';
import { Spinner } from '@chakra-ui/react';
interface Param {
  children: JSX.Element
}
function Wrapper({ children }: Param) {
  const {
    isLoading,
    error,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();
  if (isLoading) {
    return <Spinner
      thickness='4px'
      speed='0.65s'
      emptyColor='gray.200'
      color='blue.500'
      size='xl'
    />
  }
  if (error) {
    return <ErrorPage title='Oops...' description={error.message}/>
  }
  if(!isAuthenticated) {
    loginWithRedirect()
    return <></>
  }
  return <>{children}</>;
}
export default Wrapper;