import { Box, css } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BingSearchClient, ParseResponse, Recipe } from "../service/RecipeService";

interface urlProps {
  url: string;
}

const container = css({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  width: 'calc(100% - 4rem)',
  backgroundColor: 'white',
  padding: '2rem',
  margin: '2rem'
})

const title = css({
  display: 'flex',
  fontSize: '32px',
  fontWeight: 'bolder',
  borderBottom: '3px solid black',
  margin: '1rem 0 1rem 0',
  width: '100%'
})

const image = css({
  display: 'flex',
  height: '10rem',
  width: '100%',
  objectFit: 'none'
})

const ingredients = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  margin: '0.4rem 17rem 0 0',
  backgroundColor: 'white',
  height: 'auto',
  width: '100%',
  padding: '2rem',
  border: '1px solid black'
})

const description = css({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  margin: '0.4rem 1rem 0 0',
  backgroundColor: 'white',
  height: 'auto',
  width: '100%',
  padding: '2rem',
  border: '1px solid black'
})

const ingredientSpacer = css({
  display: 'flex',
  flexDirection: 'row',
  margin: '0.2rem'
})

const row = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
})

const column1 = css({
  display: 'flex',
  flexDirection: 'column',
  height: '-webkit-fill-available',
  width: '20%'
})

const column2 = css({
  display: 'flex',
  flexDirection: 'column',
  height: '-webkit-fill-available',
  width: '100%'
})

const column3 = css({
  display: 'flex',
  flexDirection: 'column',
  height: '-webkit-fill-available',
  width: '75%'
})

const indexSpacer = css({
  display: 'flex',
  margin: '0 0.4rem 0 0'
})

const someBuffer = css({
  display: 'flex',
  margin: '11.4rem 0 0.4rem 0rem',
  fontSize: '18px',
  fontWeight: 'bolder'
})

const someBuffer2 = css({
  display: 'flex',
  margin: '2rem 0 0.4rem 0rem',
  fontSize: '18px',
  fontWeight: 'bolder'
})

export default function HomePage(props: urlProps) {

  const [recipe, setRecipe] = useState<Recipe>();

  const service = new BingSearchClient();

  const onSearch = (input: string | undefined) => {
    if (input === undefined) {
      return;
    }
    service.getRecipeFromUrl({ url: input }).then((ret: ParseResponse | undefined) => {
      setRecipe(ret?.data.recipe);
      console.log(ret);
    })
  }

  useEffect(() => {
    if (props.url !== undefined && props.url.length > 0) {
      onSearch(props.url);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.url])

  return (
    <Box css={container}>
      <Box css={title}>
        {recipe?.name}
      </Box>
      <Box css={image}>
        <img 
        style={{width: '100%', height: '20rem', objectFit: 'cover'}}
          src={recipe?.image[0]}
          alt={recipe?.name}
        />
      </Box>
      
      <Box css={row}>
        <Box css={column1}>
          <Box css={someBuffer}>Ingredients:</Box>
          <Box css={ingredients}>
            {recipe?.recipeIngredient.map((item, index) => {
              return (<Box css={ingredientSpacer}>
                <Box css={indexSpacer}>
                  ({index + 1})
                </Box>{item}
              </Box>)
            })}
          </Box>
        </Box>
        <Box css={column3}>
          <Box css={column2}>
            <Box css={someBuffer}>Description:</Box>
            <Box css={description}>
              {recipe?.description}
            </Box>
            <Box css={someBuffer2}>Steps:</Box>
          </Box>
          <Box css={ingredients}>
            {recipe?.recipeInstructions?.map((item, index) => {
              return (<Box css={ingredientSpacer}>
                <Box css={indexSpacer}>
                  ({index + 1})
                </Box>{`${item.name !== undefined ? item.name + ': ' : ''}${item.text}`}
              </Box>)
            })}
          </Box>
        </Box>
      </Box>
      
      {/* <Box css={toolbar}>
        <Box css={innerToolbar}>
          <Box css={button}>{'<'}</Box>
          <Box css={button}>{'>'}</Box>
        </Box>
      </Box> */}
    </Box>
  )
}
