import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export interface RedirectProps {
    url?: string;
}

const Redirect = (props: RedirectProps) => {

    const navigate = useNavigate();

    useEffect(() => {
            let url = props.url;
            if (url === undefined) {
                url = '';
            }
            navigate('/' + url);
    })

    return <></>
}

export default Redirect;