import { ReactNode } from 'react';
import {
  Box,
  useColorModeValue,
  Drawer,
  useDisclosure,
} from '@chakra-ui/react';
import { SidebarContent } from './component/SidebarContent';
import { MobileNav } from './component/MobileNav';
// import {  HomePageMeta } from '../page'
import { RecipeSchema } from '../service/RecipeService';

interface MainLayoutProps {
  setUrl: (url: string) => void;
}

export default function MainLayout({
  children,
  props
}: {
  children: ReactNode;
  props: MainLayoutProps;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const mainMenuPages = [
  //   HomePageMeta
  // ]

  const setUrl = (url: string) => {
    props.setUrl('?' + url);
  }

  const setCurRecipe = (q: string, recipe: RecipeSchema, val: string) => {
    console.log(q);
  }

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        setCurRecipe={setCurRecipe}
        setUrl={setUrl}
        q={''}
        val={''}     />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} menuItems={
        <>
          
        </>} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}