import axios from 'axios';

export interface SearchRequest {
  count: number;
  q: string;
}

export interface ParseResponse {
  data: RecipeSchema;
}

export interface RecipeSchema {
  url: string;
  recipe: Recipe;
}

export interface Instruction {
  name: string,
  text: string
}

export interface Recipe {
  description: string,
  name: string,
  author: string,
  recipeIngredient: string[],
  recipeCategory: string[],
  keywords: string[],
  image: string[],
  recipeInstructions: Instruction[]
}

export interface SearchResponse {
  webPages: Array<{
    name: string;
    url: string;
  }>;
}

export interface RecipeSearchResponse {
  count: number;
  data: Array<RecipeSchema>;
}

interface UrlString {
  url: string
}

export class BingSearchClient {
  private BASE_URL = "https://api.voiceipe.com/api/mobile/v1";


  private buildHeader() {
    return {
      // "Ocp-Apim-Subscription-Key": this.apiKey,
      Accept: "application/json"
    };
  }

  public async search(req: SearchRequest): Promise<RecipeSearchResponse | undefined> {
    console.log(req.q)
    const item = this.BASE_URL + '/search?q=' + req.q;
    const response = await axios.get(item, {
      headers: this.buildHeader()
    }).then((res) => {
      return res;
    }).catch(err => {
      console.error(err);
      return undefined;
    });
    const retData: RecipeSearchResponse = response?.data;
    return {count: retData.data.filter(item => item.recipe !== undefined).length, data: retData.data.filter(item => item.recipe !== undefined)}
  }

  public async getRecipeFromUrl(url: UrlString): Promise<ParseResponse | undefined> {
    const response = await axios.get(this.BASE_URL + '/parser?url=' + url.url, {
      headers: this.buildHeader()
    }).then((res) => {
      return res;
    }).catch(err => {
      console.error(err);
      return undefined;
    });
    console.log(response?.data);
    const retData: ParseResponse = response?.data;
    return retData
  }
}