import { useEffect, useState } from 'react';
import './App.css';
import MainLayout from './layout/MainLayout';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import * as pages from './page'
import HomePage from './page/HomePage';
import Redirect from './page/Redirect';

export default function App() {
  const [url, setUrl] = useState('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParameters = new URLSearchParams(window.location.search);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    console.log(queryParameters);
    const q = queryParameters.get('url');
    setUrl(q || '');
    setLoaded(true);
    console.log(q);
  }, [queryParameters])

  return (
    <Router>
      <MainLayout props={{setUrl: setUrl}}>
        <Routes>
            <Route key={'callback'} element={<>
              {loaded && <Redirect url={url}></Redirect>}
            </>} path={'/callback'} />
            <Route key={'homepage'} element={<HomePage url={''}></HomePage>} path={'/'} />
            <Route key={'search'} element={<HomePage url={url}></HomePage>} path={'/search'} />
        </Routes>
      </MainLayout>
    </Router>
  );
}
