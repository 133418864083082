import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react'
import './index.css';
import App from './App';
import Wrapper from './auth/Wrapper'
import * as env from './lib/env';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Auth0Provider
        domain={env.getAuth0Domain()}
        clientId={env.getAuth0Client()}
        authorizationParams={{
          audience: env.getAuth0Audience(),
          scope: "profile email create:key read:key update:key delete:key list:keys",
          redirect_uri: `${window.location.origin}/callback`
        }}
    >
      <Wrapper>
        <ChakraProvider>
          <App/>
        </ChakraProvider>
      </Wrapper>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
